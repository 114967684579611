<template>
    <Map />
</template>

<script>
import Map from '../MapComponent'

export default {
    name: 'Heatmap',
    components: {
        Map
    },
}
</script>
